<template>
    <v-app id="inspire">
        <app-drawer></app-drawer>
        <app-toolbar></app-toolbar>
        <v-main style="margin-bottom: 50px;">
            <div style="min-height:15px;"></div>
            <transition name="fade">
                <router-view/>
            </transition>
            <app-loader></app-loader>
            <v-snackbar
                v-model="$store.getters.snackbar.display"
                :bottom="$store.getters.snackbar.y === 'bottom'"
                :left="$store.getters.snackbar.x === 'left'"
                :multi-line="$store.getters.snackbar.mode === 'multi-line'"
                :right="$store.getters.snackbar.x === 'right'"
                :timeout="$store.getters.snackbar.timeout"
                :top="$store.getters.snackbar.y === 'top'"
                :vertical="$store.getters.snackbar.mode === 'vertical'"
                >
                {{ $store.getters.snackbar.text }}
                <v-btn
                    color="pink"
                    text
                    @click="snackbar"
                >
                    Zapri
                </v-btn>
            </v-snackbar>
            <network-status></network-status>
        </v-main>
        <app-fab></app-fab>
    </v-app>
</template>

<script>
import AppFab from '@/views/layout/AppFab'
import AppToolbar from '@/views/layout/AppToolbar'
import AppDrawer from '@/views/layout/AppDrawer'
const NetworkStatus = () =>import('@/components/NetworkStatus')

export default {
    components: {
        AppFab,
        AppToolbar,
        AppDrawer,
        NetworkStatus
        
    },

    props: {
      source: String
    },

    data: () => ({

    }),

    methods: {
        user() {
            return this.$store.getters.user
        },

        snackbar() {
            this.$store.state.snackbar.display = false
        }
    },

    created() {

    },

    mounted() {

    }
}

</script>

<style>
    html { overflow-y: auto !important; }
    
    .pointer {cursor: pointer;}

    .fade-enter-active, .fade-leave-active {
        transition-property: opacity;
        transition-duration: .25s;
    }

    .fade-enter-active {
        transition-delay: .25s;
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }

    .listMarginLeft {
        margin-left:-10px !important;
    }

    .v-content__wrap {
      /*background-color: #FAFAFA !important;*/
    }

    #inspire{
        background-image: url("@../../..//assets/bckpattern.png") !important;
        background-repeat: repeat !important;
    }
</style>